import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MachinePrompt.css';

function MachinePrompt() {
  const [machineId, setMachineId] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setMachineId(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (machineId) {
      navigate(`/${machineId}`);
    }
  };

  return (
    <div className="prompt-container">
      <h1 className="prompt-title">Введите номер машинки</h1>
      <form onSubmit={handleFormSubmit} className="prompt-form">
        <input
          type="number"
          className="prompt-input"
          placeholder="Номер машинки"
          value={machineId}
          onChange={handleInputChange}
          required
        />
        <button type="submit" className="prompt-button">Ввод</button>
      </form>
    </div>
  );
}

export default MachinePrompt;
