import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './HomePage.css';

function FailurePage() {
  const styles = {
    centeredContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
      textAlign: 'center',
    },
    largeText: {
      fontSize: '2rem',
      color: '#333',
    },
  };

  return (
    <div style={styles.centeredContainer}>
      <h1 style={styles.largeText}>Произошла ошибка :( </h1>
      <h1 style={styles.largeText}>Пожалуйста отсканируйте QR код заново</h1>
    </div>
  );
}

function HomePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [machineId, setMachineId] = useState('');
  const [products, setProducts] = useState([]);
  const [machines, setMachines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    setMachineId(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (machineId) {
      navigate(`/${machineId}`);
    }
  };

  const handleOptionClick = async (product) => {
    try {
      setError(null);
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/payment?product_id=${product.id}&machine_id=${id}`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Payment creation failed');
      }

      const data = await response.json();
      const paymentUrl = data.payment_url;

      if (paymentUrl) {
        window.location.href = paymentUrl;
      } else {
        throw new Error('No payment URL provided');
      }
    } catch (err) {
      setError('Повторите заново :(');
    }
  };

  useEffect(() => {
    const fetchMachinesAndProducts = async () => {
      try {
        const machineResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/machines`);
        const machineData = await machineResponse.json();
        setMachines(machineData.machines);

        const currentMachine = machineData.machines.find((machine) => machine.id === parseInt(id));
        if (!currentMachine) {
          throw new Error('Machine not found');
        }

        const productResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/products`);
        const productData = await productResponse.json();
        setProducts(productData.products);
      } catch (err) {
        setError('Произошла ошибка :( Пожалуйста отсканируйте QR код заново');
      } finally {
        setLoading(false);
      }
    };

    fetchMachinesAndProducts();
  }, [id]);

  if (!id) {
    return (
      <div className="container">
        <h1 className="title">Введите номер машинки</h1>
        <form onSubmit={handleFormSubmit} className="machine-form">
          <input
            type="number"
            className="machine-input"
            placeholder="Номер машинки"
            value={machineId}
            onChange={handleInputChange}
            required
          />
          <button type="submit" className="button">Ввод</button>
        </form>
      </div>
    );
  }

  if (error) {
    return <FailurePage />;
  }

  return (
    <div className="container">
      <h1 className="title">Машинка #{id}</h1>
      <img src="/washing-machine.png" alt="Washing Machine" className="washing-machine" />
      <h2>Выберите опцию</h2>
      
      {loading ? (
        <div>Loading products...</div>
      ) : (
        <div>
          {products.map((product) => (
            <button
              key={product.id}
              className="button"
              onClick={() => handleOptionClick(product)}
            >
              {product.name} - {product.sum} тг.
            </button>
          ))}
        </div>
      )}

      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default HomePage;
