import React from 'react';
import { useNavigate } from 'react-router-dom';
import './UserAgreement.css';

function UserAgreement() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="user-agreement">
      <button onClick={goBack} className="back-button">←</button>
      <h1>Принципы защиты данных компании «BusyTech»</h1>
      <p>
        Компания «BusyTech» приветствует Вас и благодарит за
        проявленный интерес к нашему сервису.
      </p>
      <h2>«BusyTech» уважает Вашу частную жизнь</h2>
      <p>
        Защита частной информации при обработке личных данных является важным требованием,
        которое мы соблюдаем в нашей деятельности. Мы обрабатываем личные данные, получаемые при
        использовании наших сервисов, соблюдая конфиденциальность и действующее законодательство.
      </p>
      <h2>Сбор и обработка личных данных</h2>
      <p>
        Когда Вы используете наш сервис, например, сканируя QR-код и выбирая услуги (стирка,
        сушилка и т.д.), мы можем сохранять следующую информацию: устройство,
        с которого Вы перешли на наш сайт; выбранные услуги; продолжительность использования
        сервиса; браузер или приложение, используемые для доступа к нашему сайту, и прочие
        данные.
      </p>
      <p>
        Извлеченная информация позволяет нам улучшать наш сервис и лучше адаптировать его к
        потребностям наших клиентов.
      </p>
      <h2>Защита данных и информационная безопасность</h2>
      <p>
        Защита данных и информационная безопасность — составные части нашей корпоративной
        политики. Мы принимаем необходимые меры для обеспечения безопасности Ваших данных и
        предотвращения их несанкционированного использования.
      </p>
      <h2>Контакты</h2>
    <p>
        Если у Вас возникли вопросы по поводу обработки Ваших данных, пожалуйста, свяжитесь с
        нами по номеру: +7(707)-592-1723
    </p>
    </div>
  );
}

export default UserAgreement;
