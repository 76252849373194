import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <Link to="/user-agreement" className="footer-link">Пользовательское Соглашение</Link>
    </footer>
  );
}

export default Footer;
