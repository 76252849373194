import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import MachinePrompt from './components/MachinePrompt/MachinePrompt';
import Footer from './components/Footer/Footer';
import UserAgreement from './components/UserAgreement/UserAgreement';

function SuccessPage() {
  return (
    <div style={styles.centeredContainer}>
      <h1 style={styles.largeText}>Успешная оплата.</h1>
      <h1 style={styles.largeText}>Можете пользоваться :)</h1>
    </div>
  );
}

function FailurePage() {
  return (
    <div style={styles.centeredContainer}>
      <h1 style={styles.largeText}>Произошла ошибка :( </h1>
      <h1 style={styles.largeText}>Пожалуйста отсканируйте QR код заново</h1>
    </div>
  );
}

function ValidatedHomePage() {
  const { id } = useParams();
  const isNumeric = /^\d+$/.test(id);

  if (!isNumeric) {
    return (
      <div style={styles.centeredContainer}>
        <h1 style={styles.largeText}>Ссылка не найдена :(</h1>
      </div>
    );
  }

  return <HomePage />;
}

const styles = {
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  largeText: {
    fontSize: '2rem',
    color: '#333',
  },
};

function App() {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/" element={<MachinePrompt />} />
          <Route path="/:id" element={<ValidatedHomePage />} />
          <Route path="/user-agreement" element={<UserAgreement />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/failure" element={<FailurePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
